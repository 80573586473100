import { Link, Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import AddTutorial from "../components/add-tutorial.component";

export const ProtectedLayout2 = () => {
  const { user } = useAuth();
  const outlet = useOutlet();
console.log('q es outlet',outlet)
  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <AddTutorial></AddTutorial>
      {outlet}
    </div>
  );
};
