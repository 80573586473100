import React, { useState, useEffect } from "react";
import TutorialDataService from "../services/tutorial.service";
import { Link } from "react-router-dom";
import Popup from 'reactjs-popup';
import styled from 'styled-components';

const Button = styled.button`
  border-radius: 3px;
  border: transparent;;    
  color: #red;
  margin: 0.5em 1em;
  padding: 0.25em 1em;
  flex-basis: 30%; /* Adjust the width as needed */
  margin: 5px; /* Spacing between buttons */
`

const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  &-overlay {
    ...;
  }
  // use your custom style for ".popup-content"
  &-content {
    ...;
  }
`;
const PopupWrapper = styled.div`

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
`;

const ButtonWrapper = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between; /* Adjust this as needed */

`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 500;
`;



const TutorialsList = () => {
    const [tutorials, setTutorials] = useState([]);
    const [currentTutorial, setCurrentTutorial] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [searchTitle, setSearchTitle] = useState("");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        retrieveTutorials();
    }, []);

    const handleColorChange = (color) => {
        currentTutorial.color = color;
        updateStatus(true);
        console.log('console' + color);
    };

    const onChangeSearchTitle = (e) => {
        setSearchTitle(e.target.value);
    };

    const retrieveTutorials = () => {
        TutorialDataService.getAll()
            .then(response => {
                setTutorials(response.data);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const refreshList = () => {
        retrieveTutorials();
        setCurrentTutorial(null);
        setCurrentIndex(-1);
    };

    const setActiveTutorial = (tutorial, index) => {
        setCurrentTutorial(tutorial);
        setCurrentIndex(index);
    };

    const clearSearch = () => {
        setCurrentTutorial(null);
        setCurrentIndex(-1);
        setSearchTitle("");
        refreshList();
    };

    function searchTitleFunction() {
        setCurrentTutorial(null);
        setCurrentIndex(-1);

        TutorialDataService.findByTitle(searchTitle)
            .then(response => {
                setTutorials(response.data);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };



    function updateStatus(status) {
        var data = {
            id: currentTutorial.id,
            title: currentTutorial.firstName,
            description: currentTutorial.lastName,
            color: status ? currentTutorial.color : "",
            status: status
        };

        TutorialDataService.update(currentTutorial.id, data)
            .then(response => {
                setCurrentTutorial(prevTutorial => ({
                    ...prevTutorial,
                    status: true
                }));
                console.log(response.data);
                window.location.reload();
            })
            .catch(e => {
                console.log(e);
            });
    };

    function openModal() {
    setOpen(true);
    }


    function buttonAction(status){
        if (status) {
            updateStatus(false);
        } else {
            openModal();
        }

    }


    function closeModal(colorSelected) {
        console.log('close modal' + colorSelected)
        setOpen(false)

    };

    // ... [Render method becomes return statement]
    return (

        <div className="list row">
            <div className="col-md-8">
                <div className="input-group mb-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search by name"
                        value={searchTitle}
                        onChange={onChangeSearchTitle}
                    />
                    <div className="input-group-append">
                        <button
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={searchTitleFunction}
                        >
                            Search
                        </button>
                        <button
                            className="btn btn-danger"
                            type="button"
                            onClick={clearSearch}
                        >
                            CLEAR
                        </button>
                    </div>
                </div>
            </div>

            <h4></h4>
            <div class="container-fluid">
                <div class="row">

                    <div className="col-8">
                        <ul className="list-group" >
                            {tutorials &&
                                tutorials.map((tutorial, index) => (
                                    <li
                                        className={
                                            "list-group-item " +
                                            (index === currentIndex ? "active" : "")
                                        }
                                        onClick={() => setActiveTutorial(tutorial, index)}
                                        key={index}
                                    >
                                        {tutorial.firstName} {tutorial.lastName}
                                    </li>
                                ))}
                        </ul>
                    </div>


                    <div className="col-4">
                        {currentTutorial ? (
                            <div>
                                <h4>Participant</h4>
                                <div>
                                    <label>
                                        <strong>Name:</strong>
                                    </label>{" "}
                                    {currentTutorial.firstName + " " + currentTutorial.lastName}
                                </div>
                                <div>
                                    <label>
                                        <strong>Status:</strong>
                                    </label>{" "}
                                    {currentTutorial.status ? "Entered" : "Pending"}
                                </div>
                                <div>
                                    <label>
                                        <strong>Color:</strong>
                                    </label>{" "}
                                    {currentTutorial.color}
                                </div>
                                <StyledPopup
                                    modal
                                    closeOnDocumentClick
                                    open={open} onClose={closeModal}
                                >
                                    {close => (
                                        <PopupWrapper>
                                            <ButtonWrapper >
                                            <Button style={{background:'red'}} onClick={() => { handleColorChange("Red"); close(); }}>Red</Button>
                                            <Button style={{background:'orange'}} onClick={() => { handleColorChange("Orange"); close(); }}>Orange</Button>
                                            <Button style={{background:'yellow'}} onClick={() => { handleColorChange("Yellow"); close(); }}>Yellow</Button>
                                            <Button style={{background:'green'}} onClick={() => { handleColorChange("Green"); close(); }}>Green</Button>
                                            <Button style={{background:'blue'}} onClick={() => { handleColorChange("Blue"); close(); }}>Blue</Button>
                                            <Button style={{background:'purple'}} onClick={() => { handleColorChange("Purple"); close(); }}>Purple</Button>
                                            <Button style={{background:'pink'}} onClick={() => { handleColorChange("Pink"); close(); }}>Pink</Button>
                                            </ButtonWrapper>
                                        </PopupWrapper>
                                    )}

                                </StyledPopup>
                                <button className={`${currentTutorial.status ? "" : "btn-success"}`} onClick={() => buttonAction(currentTutorial.status)} >{currentTutorial.status ? "UNDO" : "ENTER"}</button>
                            </div>
                        ) : (
                            <div>
                                <br />
                                <p>Click one to get details</p>
                            </div>
                        )}
                    </div>


                </div>

            </div>
        </div>


    );
};

export default TutorialsList;
