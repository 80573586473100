import axios from "axios";

 export default axios.create({
  baseURL: "https://wcdoor-server-22a81d629946.herokuapp.com/api",
  headers: {
    "Content-type": "application/json"
  }
});
/*
export default axios.create({
  baseURL: "http://localhost:8081/api",
  headers: {
    "Content-type": "application/json"
  }
}); 
*/