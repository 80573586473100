import { useAuth } from "../hooks/useAuth";
import React, { useState } from "react";

export const LoginPage = () => {
  const { login } = useAuth();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

   const handleSubmit = (event) => {
    event.preventDefault();
    //const data = new FormData(event.currentTarget);
    login({
      username: username,
      password: password
    });
  };

  return (

    <form onSubmit={handleSubmit}>
                    <div className="form-group">

    <label>
      Username:
      <input
        type="text"
        value={username}
        onChange={(event) => setUsername(event.target.value)}
      />
    </label>
    </div>
    <div className="form-group">

    <label>
      Password:
      <input
        type="password"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
      />
    </label>
    </div>
    <button type="submit">Submit</button>
   

  </form>
  );
};
