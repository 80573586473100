import React, { Component } from "react";
import TutorialDataService from "../services/tutorial.service";
import { Link } from "react-router-dom";
import Popup from 'reactjs-popup';
import styled from 'styled-components'

const Button = styled.button`
  background: transparent;
  border-radius: 3px;
  border: 2px solid #BF4F74;
  color: #BF4F74;
  margin: 0 1em;
  padding: 0.25em 1em;
`

const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  &-overlay {
    ...;
  }
  // use your custom style for ".popup-content"
  &-content {
    ...;
  }
`;
const PopupWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 500;
`;




export default class TutorialsList extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
    this.retrieveTutorials = this.retrieveTutorials.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveTutorial = this.setActiveTutorial.bind(this);
    this.searchTitle = this.searchTitle.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
   // this.handleColorChange = this.handleColorChange.bind(this);


    this.state = {
      tutorials: [],
      currentTutorial: null,
      currentIndex: -1,
      searchTitle: ""
    };
  }

  componentDidMount() {
    this.retrieveTutorials();
  }

  handleColorChange = (color) => {
    console.log('console'+ color);
  }

  onChangeSearchTitle(e) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle
    });
  }

  retrieveTutorials() {
    TutorialDataService.getAll()
      .then(response => {
        this.setState({
          tutorials: response.data
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  refreshList() {
    this.retrieveTutorials();
    this.setState({
      currentTutorial: null,
      currentIndex: -1
    });
  }

  setActiveTutorial(tutorial, index) {
    this.setState({
      currentTutorial: tutorial,
      currentIndex: index
    });
  }
  clearSearch() {
    this.setState({
      currentTutorial: null,
      currentIndex: -1,
      searchTitle: ""

    });
    this.refreshList();
  }


  searchTitle() {
    this.setState({
      currentTutorial: null,
      currentIndex: -1
    });

    TutorialDataService.findByTitle(this.state.searchTitle)
      .then(response => {
        this.setState({
          tutorials: response.data
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  updatestatus(status) {
    var data = {
      id: this.state.currentTutorial.id,
      title: this.state.currentTutorial.firstName,
      description: this.state.currentTutorial.lastName,
      color: this.state.currentTutorial.color,
      status: status
    };

    TutorialDataService.update(this.state.currentTutorial.id, data)
      .then(response => {
        this.setState(prevState => ({
          currentTutorial: {
            ...prevState.currentTutorial,
            status: true
          }
        }));
        console.log(response.data);
        window.location.reload();

      })
      .catch(e => {
        console.log(e);
      });
  }





  render() {
    const { searchTitle, tutorials, currentTutorial, currentIndex } = this.state;

    return (
      <div className="list row">
        <div className="col-md-8">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search by name"
              value={searchTitle}
              onChange={this.onChangeSearchTitle}
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={this.searchTitle}
              >
                Search
              </button>
              <button
                className="btn btn-danger"
                type="button"
                onClick={this.clearSearch}
              >
                CLEAR
              </button>
            </div>
          </div>
        </div>

        <h4></h4>
        <div class="container-fluid">
          <div class="row">

            <div className="col-8">
              <ul className="list-group" >
                {tutorials &&
                  tutorials.map((tutorial, index) => (
                    <li
                      className={
                        "list-group-item " +
                        (index === currentIndex ? "active" : "")
                      }
                      onClick={() => this.setActiveTutorial(tutorial, index)}
                      key={index}
                    >
                      {tutorial.firstName} {tutorial.lastName}
                    </li>
                  ))}
              </ul>
            </div>


            <div className="col-4">
              {currentTutorial ? (
                <div>
                  <h4>Participant</h4>
                  <div>
                    <label>
                      <strong>Name:</strong>
                    </label>{" "}
                    {currentTutorial.firstName}
                  </div>
                  <div>
                    <label>
                      <strong>Status:</strong>
                    </label>{" "}
                    {currentTutorial.status ? "Entered" : "Pending"}
                  </div>
                  <div>
                    <label>
                      <strong>Color:</strong>
                    </label>{" "}
                    {currentTutorial.color}
                  </div>
                  <StyledPopup
                    trigger={<button className="button"> Open Modal </button>}
                    modal
                    closeOnDocumentClick
                   
                  >
                    <PopupWrapper> 
                    <Button onClick={() => console.log('red button')}>Red</Button>
                    <Button onClick={() => { this.handleColorChange("Orange");}}>Orange</Button>
                    
                    </PopupWrapper>
                 
                  </StyledPopup>
                  <button className={`${currentTutorial.status ? "" : "btn-success"}`} onClick={() => this.updatestatus(!currentTutorial.status)} >{currentTutorial.status ? "UNDO" : "ENTER"}</button>
                </div>
              ) : (
                <div>
                  <br />
                  <p>Click one to get details</p>
                </div>
              )}
            </div>


          </div>

        </div>
      </div>

    );
  }
}
