import { Link, Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import TutorialsList from "../components/tutorials-list.component";
import ParticipantList from "../components/participantsComponent";

export const ProtectedLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();
console.log('q es outlet',outlet)
  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <ParticipantList></ParticipantList>
      {outlet}
    </div>
  );
};
