import React, { Component } from "react";
import TutorialDataService from "../services/tutorial.service";

export default class AddTutorial extends Component {
  constructor(props) {
    super(props);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.saveTutorial = this.saveTutorial.bind(this);
    //this.saveTutorials = this.saveTutorials.bind(this);
    this.newTutorial = this.newTutorial.bind(this);
    this.onChangeLast = this.onChangeLast.bind(this);

   // this.quepasa = this.quepasa.bind(this);

    this.state = {
      id: null,
      firstName: "",
      lastName:"",
      status: false
    };
  }

  onChangeTitle(e) {
    this.setState({
      firstName: e.target.value
    });
  }

  onChangeLast(e) {
    this.setState({
      lastName: e.target.value
    });
  }
  /* quepasa(){
    const listita = ["l","sa"];
    var arrayLength = listita.length;
    for (var i = 0; i < arrayLength; i++) {
        console.log(listita[i]);
        
        this.saveTutorials(listita[i]);
        //Do something
    }

  }
  saveTutorials(name) {
    var data = {
      firstName: name,
      status: false
    };

    TutorialDataService.create(data)
      .then(response => {
        this.setState({
          id: response.data.id,
          firstName: response.data.firstName,
          status: response.data.status,
          submitted: true
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e.response.data);
      });
  } */



  saveTutorial() {
    var data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      status: this.state.status
    };

    TutorialDataService.create(data)
      .then(response => {
        this.setState({
          id: response.data.id,
          firstName: response.data.firstName,
          status: response.data.status,
          submitted: true
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e.response.data);
      });
  }

  newTutorial() {
    this.setState({
      id: null,
      firstName: "",
      lastName:"",
      submitted: false
    });
  }

  render() {
    return (
      <div className="submit-form">
        {this.state.submitted ? (
          <div>
            <h4>You submitted successfully!</h4>
            <button className="btn btn-success" onClick={this.newTutorial}>
              Add
            </button>
          </div>
        ) : (
          <div>
            <div className="form-group">
              <label htmlFor="title">First Name</label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                required
                value={this.state.title}
                onChange={this.onChangeTitle}
                name="firstName"
              />
                <label htmlFor="title">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                required
                value={this.state.lastName}
                onChange={this.onChangeLast}
                name="firstName"
              />
            </div>

            <button onClick={this.saveTutorial} className="btn btn-success">
              Submit
            </button>
          </div>
        )}
      </div>
    );
  }
}
