import React, { Component } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AddTutorial from "./components/add-tutorial.component";
import Tutorial from "./components/tutorial.component";
import TutorialsList from "./components/tutorials-list.component";
import { LoginPage } from "./pages/Login";
import { ProtectedLayout } from "./components/ProtectedLayout";
import { ProtectedLayout2 } from "./components/ProtectedLayout2";

class App extends Component {
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <Link className="navbar-brand">
            0% 
          </Link>
          <div className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to={"/"} className="nav-link">
                Participants
              </Link>
            </li>
{/*             <li className="nav-item">
              <Link to={"/add"} className="nav-link">
                ADD NEW
              </Link>
            </li> */}
          </div>
        </nav>

        <div className="container mt-3">
          <Routes>
            <Route path="/" element={<ProtectedLayout/>} />
            <Route path="/add-new-participant-0" element={<ProtectedLayout2/>} />
            <Route path="/login" element={<LoginPage/>} />
          </Routes>
        </div>
      </div>
    );
  }
}

export default App;
